.container {
  width: 100%;
  max-width: 2500px;
  padding-left: 15%;
  padding-right: 15%;
  box-sizing: border-box;
  height: 100%;
}
@media only screen and (max-width: 650px) {
  .container {
    padding-left: 5%;
    padding-right: 5%;
  }
}
