.label {
  padding: 5px 5px 5px 20px;
  border-radius: 5px;
  font-weight: 400;
  border: 1px solid #dde4e7;
  margin: 5px 0px 5px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}
