.background-container-services {
  background-image: url("../../../public/bg2.png");
  background-size: cover;
  background-position: center;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media only screen and (max-width: 850px) {
  .background-container-services {
    height: fit-content;
    padding: "6rem 0 6rem 0";
  }
}
