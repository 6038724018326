.header {
  width: 100%;
  height: 10%;
  display: grid;
  grid-template-columns: 20% 15% 15% 15% 15% 15% 15%;
  gap: 0px;
  align-items: center;
  justify-items: center;
  max-width: 2500px;
  padding-left: 15%;
  padding-right: 15%;
  box-sizing: border-box;
}
@media only screen and (max-width: 1024px) {
  .header {
    padding-left: 10%;
    padding-right: 10%;
    display: grid;
    grid-template-columns: 20% 8% 18% 18% 18% 18% 18%;
  }
}
@media only screen and (min-width: 2000px) {
  .header {
    padding-left: 15%;
    padding-right: 15%;
    display: grid;
    grid-template-columns: 17% 15% 15% 15% 15% 15% 15%;
  }
}
.header-item {
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
}
/* ------------------- Navbar ------------------ */
.header-tablet-container {
  position: fixed;
  display: flex;
  z-index: 1000;
  top: -1px;
  height: 70px;
  width: 100%;
  background-color: #0f2c4b;
}
.background-nav {
  background: transparent;
  transition: background-color 1s ease 0s;
}
.background-nav.is-open {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background: linear-gradient(
    180deg,
    rgb(17, 49, 79, 1) 15%,
    rgb(35, 86, 133) 55%,
    rgba(77, 181, 227, 1) 100%
  );
}
.main-nav {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  text-align: center;
  background: #fff;
  opacity: 0;
  z-index: -1;
  visibility: hidden;
  transition: all 0.375s;
}

.main-nav.is-open {
  position: fixed;
  opacity: 1;
  z-index: 100;
  visibility: visible;
  background: #11314f;
  content: "";
  top: 0;
  bottom: 0;
  left: 0;
  right: -15px;
  transform-origin: 0 0;
  transform: skew(-14deg) translateX(0%);
  transition: all 0.275s 0.1s;
}

/* Skewing effect on menu links */
.main-nav ul {
  display: inline-flex;
  margin-top: 30%;
  flex-direction: column;
  height: 100%;
  align-items: flex-end;
  justify-content: flex-start;
  transform: translateX(-15px) skew(-8deg);
}

.main-nav li {
  display: block;
  margin: 0.5rem 0;
  text-align: right;
  transform: skew(20deg);
}

/* Apparition effect on links */
.main-nav a {
  opacity: 0;
  transform: translateY(-10px);
}

.main-nav.is-open a {
  opacity: 1;
  transform: translateY(0);
}
.main-nav li:nth-child(1) a {
  transition: all 275ms 175ms;
}
.main-nav li:nth-child(2) a {
  transition: all 275ms 225ms;
}
.main-nav li:nth-child(3) a {
  transition: all 275ms 275ms;
}
.main-nav li:nth-child(4) a {
  transition: all 275ms 325ms;
}

/* Decoration */
.main-nav ul,
.main-nav li {
  list-style: none;
  padding: 0;
}
.main-nav a {
  display: block;
  padding: 12px 0;
  color: #fafafa;
  font-size: 7vw;
  text-decoration: none;
  font-weight: bold;
}

.open-main-nav {
  padding-top: 25px;
  z-index: 1000;
  background: none;
  border: 0;
  position: fixed;
  right: 15px;
  top: 10px;
  cursor: pointer;
}
.open-main-nav:focus {
  outline: none;
}
.burger {
  position: relative;
  display: block;
  width: 28px;
  height: 4px;
  margin: 0 auto;
  background: #ffffff;
  transform: skew(5deg);
  transition: all 0.275s;
}

.burger:after,
.burger:before {
  content: "";
  display: block;
  height: 100%;
  background: #ffffff;
  transition: all 0.275s;
}

.burger:after {
  transform: translateY(-12px) translateX(-2px) skew(-20deg);
}

.burger:before {
  transform: translateY(-16px) skew(-10deg);
}

/* Toggle State part */
.is-open .burger {
  transform: skew(5deg) translateY(-8px) rotate(-45deg);
}

.is-open .burger:before {
  transform: translateY(0px) skew(-10deg) rotate(75deg);
}

.is-open .burger:after {
  transform: translateY(-12px) translateX(10px) skew(-20deg);
  opacity: 0;
}

/* MENU Text part */

.burger-text {
  display: block;
  font-size: 0.675rem;
  letter-spacing: 0.05em;
  margin-top: 0.5em;
  text-transform: uppercase;
  font-weight: 500;
  text-align: center;
  color: #ffffff;
}
.languagepicker {
  background-color: #fff;
  display: inline-block;
  padding: 0;
  height: 40px;
  overflow: hidden;
  transition: all 0.3s ease;
  margin: 0 50px 10px 0;
  vertical-align: top;
}

.languagepicker:hover {
  height: 81px;
}

.languagepicker a {
  color: #000;
  text-decoration: none;
}
.languageSlot {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  font-size: medium;
}
.languagepicker li {
  display: block;
  background: transparent;
  padding: 0px 20px;
  line-height: 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: medium;
  border-top: 1px solid #eee;
}

.languagepicker li:hover {
  background-color: #eee;
}

.languagepicker a:first-child li {
  border: none;
  background: transparent;
}

.languagepicker li img {
  margin-right: 10px;
}

.roundborders {
  border-radius: 5px;
}

.large:hover {
  height: 245px;
}
.selectLanguage {
  font-family: "League Spartan", sans-serif;
  font-size: medium;
  background-color: #bed2e00b;
  font-weight: 600;
  color: rgb(255, 255, 255);
  height: 2.5rem;
  border: 1px solid #bed2e000;
  border-radius: 20px;
  width: 150px;
  padding: 10px 10px 10px 18px;
  background-image: linear-gradient(
      45deg,
      transparent 50%,
      rgb(255, 255, 255) 50%
    ),
    linear-gradient(135deg, rgb(255, 255, 255) 50%, transparent 50%);
  background-position: calc(100% - 15px) calc(1em + 2px),
    calc(100% - 10px) calc(1em + 2px), calc(100% - 1.6em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
  &:focus {
    outline: 1px solid #bbefff25;
  }
}
