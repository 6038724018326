.background-container-clients {
  height: 50vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media only screen and (max-width: 500px) {
  .background-container-clients {
    height: fit-content;
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
}
