.button {
  padding: 5px;
  cursor: pointer;
  border-radius: 5px;
  font-family: "League Spartan";

  transition: #5ac5f4 0.3s, color 0.3s;

  &.primary {
    background: transparent;
    color: white;
    border: none;
    font-weight: 500;

    &:hover {
      background: transparent;
      color: white;
    }
  }

  &.secondary {
    background: #5ac5f4;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    border: 1px solid #5ac5f4;
    height: 2.5rem;
    letter-spacing: 1.2px;
    &:hover {
      background: #47a6ce;
      color: white;
    }
  }
  &.outline {
    background: white;
    color: black;
    font-weight: bold;
    border: 1px solid #dde4e7;
    height: 3rem;
    margin: 5px 0px 5px 0px;

    &:hover {
      background: #132945;
      color: white;
    }
  }
}

.button.small {
  font-size: 12px;
}

.button.normal {
  font-size: 16px;
}

.button.large {
  font-size: 20px;
}
