.background-container-footer {
  background-image: url("../../../public/bg5.png");
  background-size: cover;
  background-position: center;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 6rem;
  padding-bottom: 5rem;
}

.input-textarea {
  font-family: "League Spartan", sans-serif;
  font-size: medium;
  height: 60px;
  overflow: scroll;
  border: 1px solid #bed2e0;
  border-radius: 20px;
  width: calc(100% - 30px);
  padding: 20px 10px 20px 20px;
  resize: none;
  &:focus {
    outline: 1px solid #00bcf3;
  }
}
.error-input-textarea {
  overflow: scroll;
  font-family: "League Spartan", sans-serif;
  font-size: medium;
  height: 60px;
  resize: none;
  border: 1px solid #ff0000;
  border-radius: 20px;
  width: calc(100% - 30px);
  padding: 20px 10px 20px 20px;
  &:focus {
    outline: 1px solid #eb5757;
  }
}
.input {
  font-family: "League Spartan", sans-serif;
  font-size: medium;
  color: black;
  height: 20px;
  border: 1px solid #bed2e0;
  border-radius: 20px;
  width: calc(100% - 30px);
  padding: 10px 10px 10px 20px;

  &:focus {
    outline: 1px solid #00bcf3;
  }
}
.error-input {
  font-family: "League Spartan", sans-serif;
  font-size: medium;
  color: black;
  height: 20px;
  border: 1px solid #ff0000;
  border-radius: 20px;
  width: calc(100% - 30px);
  padding: 10px 10px 10px 20px;
  &:focus {
    outline: 1px solid #eb5757;
  }
}

.input-select {
  font-family: "League Spartan", sans-serif;
  font-size: medium;
  color: black;
  height: 42px;
  border: 1px solid #bed2e0;
  border-radius: 20px;
  width: calc(100% - 2em);
  padding: 10px 2em 10px 20px;
  background-image: linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%),
    linear-gradient(to right, #ccc, #ccc);
  background-position: calc(100% - 15px) calc(1em + 2px),
    calc(100% - 10px) calc(1em + 2px), calc(100% - 1.6em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;

  &:focus {
    outline: 1px solid #00bcf3;
  }
}
@media only screen and (max-width: 500px) {
  .input-select {
    width: 100%;
  }
}
.error-select {
  font-family: "League Spartan", sans-serif;
  font-size: medium;
  color: black;
  height: 42px;
  border: 1px solid #ff0000;
  border-radius: 20px;
  width: 100%;
  padding: 10px 10px 10px 20px;
  background-image: linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%),
    linear-gradient(to right, #ccc, #ccc);
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;

  &:focus {
    outline: 1px solid #eb5757;
  }
}

.form-contact-custom {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
@media only screen and (max-width: 800px) {
  .form-contact-custom {
    align-items: center;
  }
}
