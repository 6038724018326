.background-container {
  background-image: url("../../../public/bg1.png");
  background-size: cover;
  background-position: center;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.css-typing p {
  font-weight: 800;
  font-size: 6vw;
  white-space: nowrap;
  overflow: hidden;
  color: white;
  font-family: "League Spartan";
  text-align: center;
  border-right: 3px solid rgba(255, 255, 255, 0.75);
  margin-top: 7rem;
}
@media only screen and (min-width: 1440px) {
  .css-typing p {
    font-size: 5vw;
  }
}
@media only screen and (max-width: 1000px) {
  .css-typing p {
    font-size: 7vw;
  }
}
@media only screen and (max-width: 800px) {
  .css-typing p {
    font-size: 7.5vw;
  }
}
@media only screen and (max-width: 450px) {
  .css-typing p {
    font-size: 12vw;
  }
}
.css-typing p:nth-child(1) {
  margin-bottom: 1rem;
  width: 100%;
  animation: type 3s steps(40, end);
  animation-fill-mode: forwards;
}

.css-typing p:nth-child(2) {
  width: 100%;
  opacity: 0;
  margin-top: 1rem;
  animation: type2 3s steps(40, end), blink 500ms steps(44) infinite;
  animation-delay: 3s;
  animation-fill-mode: forwards;
  @media only screen and (max-width: 450px) {
    animation: type2 4s steps(40, end);
    animation-delay: 4s;
    margin-bottom: 1rem;
    animation-fill-mode: forwards;
  }
}

.css-typing p:nth-child(3) {
  @media only screen and (max-width: 450px) {
    margin-top: 1rem;
    width: 100%;
    opacity: 0;
    animation: type3 4s steps(40, end), blink 500ms steps(44) infinite;
    animation-delay: 8s;
    animation-fill-mode: forwards;
  }
}

@keyframes type {
  0% {
    width: 0;
  }
  99% {
    border-right: 3px solid rgba(255, 255, 255, 0.75);
  }
  100% {
    border: none;
  }
}

@keyframes type2 {
  0% {
    width: 0;
  }
  1% {
    opacity: 1;
  }
  99% {
    border-right: 3px solid rgba(255, 255, 255, 0.75);
  }
  100% {
    opacity: 1;
    border: none;
  }
}
@keyframes type3 {
  0% {
    width: 0;
  }
  1% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes blink {
  from {
    border-right: 3px solid rgba(255, 255, 255, 0.75);
  }
  to {
    border-right-color: transparent;
  }
}
.type {
  font-family: monospace;
  color: #0000;
  background: linear-gradient(-90deg, #00dffc 5px, #0000 0) 10px 0,
    linear-gradient(#00dffc 0 0) 0 0;
  background-size: calc(var(--n) * 1ch) 200%;
  -webkit-background-clip: padding-box, text;
  background-clip: padding-box, text;
  background-repeat: no-repeat;
  animation: b 0.7s infinite steps(1),
    t calc(var(--n) * 0.3s) steps(var(--n)) forwards;
}
@keyframes t {
  from {
    background-size: 0 200%;
  }
}
@keyframes b {
  50% {
    background-position: 0 -100%, 0 0;
  }
}
