.card {
  padding: 1.5rem;
  background: white;
  position: relative;
  display: flex;
  align-items: flex-end;
  transition: 0.4s ease-out;
  box-shadow: 0px 7px 10px rgba(black, 0.5);
  cursor: pointer;

  &:hover {
    &:before {
      opacity: 1;
    }
    .info {
      opacity: 1;
      transform: translateY(0px);
    }
    .main-info {
      display: none;
      transition: opacity 2s cubic-bezier(0.68, -0.6, 0.32, 1.6) 4ms;
    }
  }
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background: rgba(#071321, 0.8);
    z-index: 2;
    transition: 0.5s;
    opacity: 0;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }
  .main-info {
    position: relative;
    z-index: 3;
    color: white;
    opacity: 1;
    transform: translateY(0px);

    h6 {
      width: 100%;
      text-align: left;
      margin: 0px 0px 5px 0px;
      font-size: 1.5rem;
    }
    .name-job-custom {
      margin: 0px;
      width: 100%;
      text-align: left;
      font-size: small;
      color: white;
      text-transform: uppercase;
      line-height: 20px;
    }
  }
  .info {
    position: relative;
    z-index: 3;
    color: white;
    cursor: default;
    opacity: 0;
    transform: translateY(30px);
    transition: opacity 0.5s ease-out, transform 0.5s ease-out;
    h6 {
      width: 100%;
      text-align: left;
      margin: 0px 0px 5px 0px;
      font-size: 1.5rem;
    }
    p {
      line-height: 15px;
      text-align: left;
      letter-spacing: 1px;
      font-size: 15px;
      margin-top: 8px;
    }
    .name-job-custom {
      color: white;
      text-transform: uppercase;
      line-height: 20px;
    }
  }
}
