.background-container-what-means-evosa {
  background-image: url("../../../public/bg4.png");
  background-size: cover;
  background-position: center;
  height: 48vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media only screen and (max-width: 500px) {
  .background-container-what-means-evosa {
    height: fit-content;
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
}
